.content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    padding: 20px;
  }
  
  .section-title {
    margin-top: 24px;
    margin-bottom: 10px;
    font-size: 44px;
    font-weight: 300;
    line-height: 100px;
    letter-spacing: 0px;
  }
  
  .client-link {
    position: relative;
    text-decoration: none;
    border: 3px solid #3256a8;
    box-shadow: 2px 2px 10px black;
    border-radius: 10px;
    cursor: pointer;
  }

  .clients-container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  
  .client-image {
    max-height: 150px; /* Limit image height to fit in the card */
    object-fit: contain;
    opacity: 0.4; /* Maintain image aspect ratio */
  }
  
  .client-name {
    position: absolute;
    bottom: 0;
    text-align: center;
    color: #3256a8;
    font-size: 1.2rem;
  }

  @media (max-width: 800px) {
    .clients-container{
        grid-template-columns: repeat(1, 1fr);
    }
  }
  
